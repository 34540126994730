.imgCopyIcon {
  border: transparent;
  background-color: transparent;
  img {
    height: 20px;
    width: 20px;
  }
}
.uploadImgs {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  button {
    border: none;
    color: white;
    padding: 0.6rem 1rem 0.6rem 1rem;
    border-radius: 30px;
    background-color: #0087f7;
  }
}
.dropzone {
  border: 2px dashed #0087f7;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.thumbs-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;

  flex-wrap: wrap;

  margin-top: 16px;
  img {
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.21);
  }
}
.upload {
  height: 100px;
  width: 100px;
  opacity: 0.2;
}
.upladImgLabel {
  font-size: 13px;
  color: rgba(128, 128, 128, 0.66);
}
.thumb {
  position: relative;
  margin: 8px;
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.thumb-img {
  display: block;
  width: 100%;
  height: 100%;
}
.input-file {
  display: none;
}
.remove-icon {
  background-color: rgba(5, 3, 3, 0.331);
  position: absolute;
  z-index: 99;
  border-radius: 10px;
  padding: 4px;
  top: 5px;
  right: 5px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  border: none !important;
}
